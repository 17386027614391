import { formatLocaleDate, timezone } from 'lib/date'
import { useIntl } from 'react-intl'

type FormatDateTimeProps = {
  date: string | Date
  endDate?: string | Date
  format: string
  showTZ?: boolean
}
const FormatDateTime = ({
  date,
  endDate,
  format,
  showTZ,
}: FormatDateTimeProps) => {
  const { formatMessage, locale } = useIntl()
  const formatKey = formatMessage({ id: format })

  const start = new Date(date)
  const end = endDate ? new Date(endDate) : undefined

  const displayDate = formatLocaleDate(
    { date: start, endDate: end },
    formatKey,
    locale
  )

  const tz = showTZ && timezone(start, locale)

  return <>{tz ? `${displayDate} (${tz})` : displayDate}</>
}

export default FormatDateTime
